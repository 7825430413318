import { Meld, Tile } from 'hk-mahjong'
import { MeldType } from 'hk-mahjong/lib/meld/MeldType'

export default function countNumberOfEmptyCells(input: Array<Tile | Meld>) {
  let result = 14
  for (const item of input) {
    if (item instanceof Tile) {
      result--
    } else if (item instanceof Meld && item.getMeldType() === MeldType.EYES) {
      result -= 2
    } else {
      result -= 3
    }
  }
  return result
}
