import React from 'react'
import { Tile } from 'hk-mahjong'
import { displayTiles } from '../grid/CurrentRow'
import { MeldCell } from '../grid/MeldCell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isChineseMode: boolean
}

export const InfoModal = ({ isOpen, handleClose, isChineseMode }: Props) => {
  return (
    <BaseModal
      title={isChineseMode ? '說明' : 'Rules'}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {isChineseMode ? (
          <>
            輸入麻雀，以搜尋所有食糊的可能性
            <br />
            3番起糊，標準的食糊牌型爲「四組一對」
          </>
        ) : (
          <>
            Input tiles to explore all possibilities of Winning Hand.
            <br />
            <br />A Winning Hand should have a minimum Faan value of 3, <br />
            with 14-18 tiles formed by <strong>5 Melds</strong>, including
            exactly 1 Eyes.
          </>
        )}
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <MeldCell tiles={'🀈🀈🀈'} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {isChineseMode ? (
          <>碰: 三張相同的牌</>
        ) : (
          <>
            Three identical tiles could form a{' '}
            <strong>
              <i>Pong</i>{' '}
            </strong>
          </>
        )}
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <MeldCell tiles={'🀗🀗🀗🀗'} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {isChineseMode ? (
          <>槓: 四張相同的牌</>
        ) : (
          <>
            Four identical tiles could form a{' '}
            <strong>
              <i>Kong</i>{' '}
            </strong>
          </>
        )}
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <MeldCell tiles={'🀜🀝🀞'} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {isChineseMode ? (
          <>上: 三張順序的同門數字牌</>
        ) : (
          <>
            Three suited tiles with the same suit in numerical sequence could
            form a{' '}
            <strong>
              <i>Chow</i>{' '}
            </strong>
          </>
        )}
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <MeldCell tiles={'🀀🀀'} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {isChineseMode ? (
          <>眼: 兩張相同的牌</>
        ) : (
          <>
            Two identical tiles could form a pair of{' '}
            <strong>
              <i>Eyes</i>{' '}
            </strong>
          </>
        )}
      </p>

      <div className="flex flex-wrap justify-center mb-1 mt-6 text-gray-500 dark:text-gray-300">
        {/* <MeldCell tiles={'🀇🀏🀙🀡🀐🀘🀀🀁🀂🀃🀄🀅🀆'} />{' '} */}
        <div className="flex justify-center">
          {displayTiles([
            new Tile('🀇'),
            new Tile('🀏'),
            new Tile('🀙'),
            new Tile('🀡'),
            new Tile('🀐'),
            new Tile('🀘'),
          ])}
        </div>
        <div className="flex justify-center">
          {displayTiles([
            new Tile('🀀'),
            new Tile('🀁'),
            new Tile('🀂'),
            new Tile('🀃'),
            new Tile('🀄'),
            new Tile('🀅'),
            new Tile('🀆'),
          ])}
        </div>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {isChineseMode ? (
          <>
            「十三么」屬特殊牌型。集齊十三種么九牌和番子牌（各一張），再加其中一張做眼。
          </>
        ) : (
          <>
            <strong>
              <i>Thirteen Orphans</i>{' '}
            </strong>
            is a non-standard special hand, which consists of the above tiles,
            and a 14th tile which could be any of the above tiles.
          </>
        )}
      </p>
    </BaseModal>
  )
}
