import React from 'react'
import GraphemeSplitter from 'grapheme-splitter'
import { Cell } from './Cell'
import { v4 as uuidv4 } from 'uuid'

export const MeldCell = (props: { tiles: string }) => {
  const graphemeSplitter = new GraphemeSplitter()
  const tiles = graphemeSplitter.splitGraphemes(props.tiles)
  return (
    <div className="flex justify-center border-2 dark:border-amber-400 border-rose-600 p-1 mx-2 my-1 rounded-md">
      {tiles.map((symbol) => (
        <Cell value={symbol} key={uuidv4()} />
      ))}
    </div>
  )
}
