import React from 'react'
import { Switch } from '@headlessui/react'

export default function ToggleSwitch(props: {
  onChange: () => void
  isChecked: boolean
  disabled?: boolean
  id?: string
}) {
  // const [enabled, setEnabled] = useState(props.isChecked)

  return (
    <div className="py-2" id={props.id}>
      <Switch
        disabled={props.disabled}
        checked={props.isChecked}
        onChange={() => {
          props.onChange()
          // setEnabled(!enabled)
        }}
        className={`${
          props.isChecked
            ? 'bg-teal-900 dark:bg-green-400 '
            : props.disabled
            ? 'bg-slate-100'
            : 'bg-slate-300'
        }
          relative inline-flex flex-shrink-0 h-[28px] w-[52px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 
          focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span
          aria-hidden="true"
          className={`${props.isChecked ? 'translate-x-6' : 'translate-x-0'}
            pointer-events-none inline-block h-[24px] w-[24px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
        />
      </Switch>
    </div>
  )
}
