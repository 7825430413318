import React from 'react'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isChineseMode: boolean
}

export const DisclaimerModal = ({
  isOpen,
  handleClose,
  isChineseMode,
}: Props) => {
  return (
    <BaseModal
      title={isChineseMode ? '免責聲明' : 'Disclaimer'}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {isChineseMode
          ? '在本網站所載的資料只供參考之用，本網站不能擔保或保證內容全部正確或詳盡。對於任何人因使用本網站而直接或間接引致的損失、損害、費用或開支，本網站不會承擔任何形式的法律責任。'
          : `Information on this web site is for reference only.  This website does not guarantee or warrant the accuracy or completeness of its content.  This website accepts no liability for any loss and damages arising directly or indirectly from or related to the use of this website.`}
      </p>
    </BaseModal>
  )
}
