import React from 'react'
import { WinningHand, FaanCalculator, Hand } from 'hk-mahjong'
import { MeldCell } from '../grid/MeldCell'
import { BaseModal } from './BaseModal'
import { v4 as uuidv4 } from 'uuid'
import { Cell } from '../grid/Cell'
import { connect, ConnectedProps } from 'react-redux'
import { BonusConfig } from '../../constants/types'
import FaanCalculationConfig from 'hk-mahjong/lib/calculateFaan/FaanCalculationConfig'
import FaanCalculationDetail from 'hk-mahjong/lib/calculateFaan/FaanCalculationDetail'

interface RootState {
  bonus: BonusConfig
}

const mapState = (state: RootState) => ({ ...state.bonus })

const connector = connect(mapState)
type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  isOpen: boolean
  handleClose: () => void
  winningHands: (WinningHand | Hand)[]
  isChineseMode: boolean
  isReportIssueMode: boolean
  setIsReportIssueMode: (input: boolean) => void
  userFeedback: string
  setUserFeedback: (input: string) => void
  hasSubmittedFeedback: boolean
  setHasSubmittedFeedback: (input: boolean) => void
}

const showThirteenOrphans = (input: WinningHand) => {
  const tiles = input.toString()
  const firstHalf = []
  const secondHalf = []
  let i = 0
  for (const tile of tiles) {
    if (i < 7) {
      firstHalf.push(<Cell value={tile} />)
    } else {
      secondHalf.push(<Cell value={tile} />)
    }
    i++
  }
  return (
    <>
      <div className="flex flex-wrap justify-center mb-1 mt-4">{firstHalf}</div>
      <div className="flex flex-wrap justify-center mb-1 mt-4">
        {secondHalf}
      </div>
    </>
  )
}

const Modal = ({
  isOpen,
  handleClose,
  winningHands,
  isChineseMode,
  isSelfPick,
  isFullyConcealed,
  seatWind,
  roundWind,
  hasSpringTile,
  hasSummerTile,
  hasAutumnTile,
  hasWinterTile,
  hasPlumTile,
  hasLilyTile,
  hasChrysanthemumTile,
  hasBambooTile,
  isEightImmortalsCrossingTheSea,
  isFlowerHand,
  isRobbingKong,
  isWinByDoubleKong,
  isWinByKong,
  isWinByLastCatch,
  setBonusFaanDueToZeroExtraTiles,
  isReportIssueMode,
  setIsReportIssueMode,
  userFeedback,
  setUserFeedback,
  hasSubmittedFeedback,
  setHasSubmittedFeedback,
}: Props) => {
  const isThirteenOrphan =
    winningHands.length === 1 &&
    winningHands[0].toString().split(' ').length === 1

  const winningConfig: FaanCalculationConfig = {
    selfPick: isSelfPick,
    fullyConcealedHand: isFullyConcealed,
    extraTiles: {
      spring: hasSpringTile,
      summer: hasSummerTile,
      autumn: hasAutumnTile,
      winter: hasWinterTile,
      plum: hasPlumTile,
      lily: hasLilyTile,
      chrysanthemum: hasChrysanthemumTile,
      bamboo: hasBambooTile,
    },
    eightImmortalsCrossingTheSea: isEightImmortalsCrossingTheSea,
    flowersHand: isFlowerHand,
    robbingKong: isRobbingKong,
    winByDoubleKong: isWinByDoubleKong,
    winByKong: isWinByKong,
    winByLastCatch: isWinByLastCatch,
    enableBonusFaanDueToZeroExtraTile: setBonusFaanDueToZeroExtraTiles,
  }

  if (seatWind !== null) {
    winningConfig['seatWind'] = seatWind
  }

  if (roundWind !== null) {
    winningConfig['roundWind'] = roundWind
  }

  return (
    <BaseModal
      title={
        hasSubmittedFeedback
          ? ''
          : isChineseMode
          ? isReportIssueMode
            ? '提供回饋'
            : '食糊組合'
          : isReportIssueMode
          ? 'Report Issue'
          : 'Result'
      }
      isOpen={isOpen}
      handleClose={handleClose}
    >
      {isReportIssueMode || hasSubmittedFeedback ? (
        ''
      ) : (
        <div
          className="text-right text-xs text-gray-500 dark:text-gray-300 italic cursor-pointer"
          onClick={() => {
            setIsReportIssueMode(true)
          }}
        >
          {isChineseMode ? '提供回饋' : 'Report Issue'}
        </div>
      )}

      {hasSubmittedFeedback ? (
        <p className="py-6 text-gray-500 dark:text-gray-300 ">
          {isChineseMode
            ? '多謝你的寶貴意見。 🙏'
            : 'Thank you for your valuable feedback. 🙏'}
        </p>
      ) : (
        ''
      )}

      {isReportIssueMode && !hasSubmittedFeedback ? (
        <div className="mt-8 mb-16">
          <textarea
            style={{ resize: 'none', outline: 'none' }}
            className="
            form-textarea mt-1 block w-full 
            border-2 border-gray-400 
            focus:border-indigo-500 
            dark:border-sky-700 focus:dark:border-amber-500
            rounded-md p-2"
            rows={4}
            placeholder={
              isChineseMode
                ? '[備註欄] 如有需要，可填寫此欄提供資料，以便管理團隊跟進。'
                : '[Optional] Leave a comment to the admin team'
            }
            value={userFeedback}
            onChange={(event) => {
              setUserFeedback(event.target.value)
            }}
          ></textarea>
          <div className="flex justify-end mt-6">
            <button
              type="button"
              className="
            mr-6
            flex items-center 
            px-2.5 py-1.5 
            border border-transparent text-base font-medium rounded 
            text-indigo-700 bg-indigo-100 dark:text-sky-700 dark:bg-sky-100 
            hover:ring-2 hover:ring-offset-2
            hover:bg-indigo-200 hover:outline-none hover:ring-indigo-500 
            dark:hover:bg-sky-800 dark:hover:outline-none  dark:hover:ring-sky-800 dark:hover:text-white
            select-none"
              onClick={() => {
                const result: {
                  message: string
                  hands: {
                    hand: string
                    config: FaanCalculationConfig
                    calculation: {
                      value: number | '∞'
                      details: FaanCalculationDetail[]
                    }
                  }[]
                } = { message: userFeedback, hands: [] }
                winningHands.forEach((winningHand) => {
                  const calculation = FaanCalculator.calculate(
                    winningHand,
                    winningConfig
                  )
                  result['hands'].push({
                    hand: winningHand.toString(),
                    config: winningConfig,
                    calculation,
                  })
                })
                setHasSubmittedFeedback(true)
                setIsReportIssueMode(false)
                fetch('https://api.hkmahjong.net/feedback', {
                  method: 'POST',
                  mode: 'no-cors',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(result),
                })
              }}
            >
              {isChineseMode ? '提交' : 'Send'}
            </button>
            <button
              type="button"
              className="
         flex items-center 
        px-2.5 py-1.5 
        border border-transparent text-base font-medium rounded 
        text-rose-700 bg-rose-100 dark:text-rose-700 dark:bg-rose-100 
        hover:ring-2 hover:ring-offset-2
        hover:bg-rose-200 hover:outline-none hover:ring-rose-500 
        dark:hover:bg-rose-800 dark:hover:outline-none  dark:hover:ring-rose-800 dark:hover:text-white
        select-none"
              onClick={() => {
                setIsReportIssueMode(false)
              }}
            >
              {isChineseMode ? '取消' : 'Cancel'}
            </button>
          </div>
        </div>
      ) : (
        ''
      )}

      {hasSubmittedFeedback ? (
        ''
      ) : isThirteenOrphan && winningHands[0] instanceof WinningHand ? (
        <div className="my-5" key={uuidv4()}>
          {showThirteenOrphans(winningHands[0])}
          <p className="text-sm text-gray-500 dark:text-gray-300 flex flex-wrap justify-center">
            {' '}
            {FaanCalculator.calculate(winningHands[0], winningConfig)[
              'details'
            ].map((detail, index) =>
              isChineseMode ? (
                <span key={uuidv4()}>
                  <span className="mx-2">{index > 0 ? '+' : ''}</span>
                  {detail['name']}({detail['value']}番)
                </span>
              ) : (
                <span key={uuidv4()}>
                  <span className="mx-2">{index > 0 ? '+' : ''}</span>
                  {detail['name']}({detail['value']})
                </span>
              )
            )}
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-300">
            {isChineseMode ? '番數 ' : 'Faan value '}={' '}
            <span className="faanValue">
              {FaanCalculator.calculate(winningHands[0], winningConfig)[
                'value'
              ] === '∞'
                ? isChineseMode
                  ? '無限番 ∞'
                  : 'Faan value cap ∞'
                : FaanCalculator.calculate(winningHands[0], winningConfig)[
                    'value'
                  ]}
            </span>
          </p>
        </div>
      ) : (isEightImmortalsCrossingTheSea || isFlowerHand) &&
        winningHands[0] instanceof Hand ? (
        <div className="my-5" key={uuidv4()}>
          <div className="text-sm text-gray-700 dark:text-gray-300 mb-3 text-5xl">
            {isEightImmortalsCrossingTheSea ? (
              <div className="flex flex-wrap justify-center">{'🀦🀧🀨🀩🀢🀣🀥🀤'}</div>
            ) : (
              <div className="flex flex-wrap justify-center">
                <div>{hasSpringTile ? '🀦' : ''}</div>
                <div>{hasSummerTile ? '🀧' : ''}</div>
                <div>{hasAutumnTile ? '🀨' : ''}</div>
                <div>{hasWinterTile ? '🀩' : ''}</div>
                <div>{hasPlumTile ? '🀢' : ''}</div>
                <div>{hasLilyTile ? '🀣' : ''}</div>
                <div>{hasChrysanthemumTile ? '🀥' : ''}</div>
                <div>{hasBambooTile ? '🀤' : ''}</div>
              </div>
            )}
          </div>
          <p className="text-sm text-gray-500 dark:text-gray-300 flex flex-wrap justify-center">
            {' '}
            {FaanCalculator.calculate(winningHands[0], winningConfig)[
              'details'
            ].map((detail, index) =>
              isChineseMode ? (
                <span key={uuidv4()}>
                  <span className="mx-2">{index > 0 ? '+' : ''}</span>
                  {detail['name']}({detail['value']}番)
                </span>
              ) : (
                <span key={uuidv4()}>
                  <span className="mx-2">{index > 0 ? '+' : ''}</span>
                  {detail['name']}({detail['value']})
                </span>
              )
            )}
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-300">
            {isChineseMode ? '番數 ' : 'Faan value '}={' '}
            <span className="faanValue">
              {FaanCalculator.calculate(winningHands[0], winningConfig)[
                'value'
              ] === '∞'
                ? isChineseMode
                  ? '無限番 ∞'
                  : 'Faan value cap ∞ (eight immortal crossing the sea)'
                : FaanCalculator.calculate(winningHands[0], winningConfig)[
                    'value'
                  ]}
            </span>
          </p>
        </div>
      ) : (
        winningHands.map((winningHand, index) => (
          <div className="my-4" key={uuidv4()}>
            {index > 0 ? <hr /> : ''}
            <div className="flex flex-wrap justify-center mb-1 mt-4">
              {winningHand
                .toString()
                .split(' ')
                .map((meld) => (
                  <MeldCell tiles={meld} key={uuidv4()} />
                ))}
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300 flex flex-wrap justify-center">
              {' '}
              {FaanCalculator.calculate(winningHand, winningConfig)[
                'details'
              ].map((detail, index) =>
                isChineseMode ? (
                  <span key={uuidv4()}>
                    <span className="mx-2">{index > 0 ? '+' : ''}</span>
                    {detail['name']}({detail['value']}番)
                  </span>
                ) : (
                  <span key={uuidv4()}>
                    <span className="mx-2">{index > 0 ? '+' : ''}</span>
                    {detail['name']}({detail['value']})
                  </span>
                )
              )}
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-300">
              {isChineseMode ? '番數 ' : 'Faan value '}={' '}
              <span className="faanValue">
                {FaanCalculator.calculate(winningHand, winningConfig)[
                  'value'
                ] === '∞'
                  ? isChineseMode
                    ? '無限番 ∞'
                    : 'Faan value cap ∞ '
                  : FaanCalculator.calculate(winningHand, winningConfig)[
                      'value'
                    ]}
              </span>
            </p>
            {/* <div>
              {FaanCalculator.calculate(winningHand, winningConfig)[
                'details'
              ].map((detail) => (
                <span className="mx-2" key={uuidv4()}>
                  {detail['name']}: {detail['value']}
                </span>
              ))}
            </div> */}
          </div>
        ))
      )}
    </BaseModal>
  )
}

export const ResultModal = connector(Modal)
