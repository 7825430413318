import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './reducers'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import reportWebVitals from './reportWebVitals'
// import EnglishPrivacyPolicy from './components/privacy/EnglishPrivacyPolicy'

const store = createStore(rootReducer)

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Provider store={store}>
        <App />
      </Provider>
    ),
  },
  // {
  //   path: '/privacy-policy',
  //   element: <EnglishPrivacyPolicy />,
  // },
])

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
