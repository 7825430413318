import { ArrowCircleDownIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'

interface IEvent {
  preventDefault: () => void
}

const InstallButton = () => {
  const [supportsPWA, setSupportsPWA] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [promptInstall, setPromptInstall] = useState<any>(null)

  useEffect(() => {
    const handler = (e: IEvent) => {
      e.preventDefault()
      // console.log('we are being triggered :D')
      setSupportsPWA(true)
      setPromptInstall(e)
    }
    window.addEventListener('beforeinstallprompt', handler)

    return () => window.removeEventListener('transitionend', handler)
  }, [])

  const onClick = (e: IEvent) => {
    e.preventDefault()
    if (!promptInstall) {
      return
    }
    promptInstall.prompt()
  }
  if (!supportsPWA) {
    return null
  }
  return (
    <ArrowCircleDownIcon
      className="mx-2 h-6 w-6 cursor-pointer dark:stroke-white"
      onClick={onClick}
    />
  )
}

export default InstallButton
