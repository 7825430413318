import React from 'react'
import { FULLY_CONCEALED, SELF_PICK } from '../../constants/strings'
import { BaseModal } from './BaseModal'
import WindOptions from './WindOptions'
import FlowerOptions from './ExtraTilesOptions'
import ToggleSwitch from './ToggleSwitch'
import { connect, ConnectedProps } from 'react-redux'
import {
  updateIsFullyConcealed,
  updateIsSelfPick,
  updateRoundWind,
  updateSeatWind,
  updateEightImmortalsCrossingTheSeaBoolean,
  updateFlowerHandBoolean,
  updateWinByLastCatchBoolean,
  updateRobbingKongBoolean,
  updateWinByDoubleKongBoolean,
  updateWinByKongBoolean,
  updateSettingOfBonusFaanDueToZeroExtraTiles,
} from '../../actions/index'
import { BonusConfig } from '../../constants/types'

interface RootState {
  bonus: BonusConfig
}

const mapState = (state: RootState) => ({ ...state.bonus })

const mapDispatch = {
  updateIsFullyConcealed,
  updateIsSelfPick,
  updateRoundWind,
  updateSeatWind,
  updateEightImmortalsCrossingTheSeaBoolean,
  updateFlowerHandBoolean,
  updateWinByLastCatchBoolean,
  updateRobbingKongBoolean,
  updateWinByDoubleKongBoolean,
  updateWinByKongBoolean,
  updateSettingOfBonusFaanDueToZeroExtraTiles,
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  isOpen: boolean
  handleClose: () => void
  isChineseMode: boolean
}

const BonusValueModal = ({
  isOpen,
  handleClose,
  isChineseMode,
  isSelfPick,
  isFullyConcealed,
  roundWind,
  seatWind,
  hasSpringTile,
  hasSummerTile,
  hasAutumnTile,
  hasWinterTile,
  hasPlumTile,
  hasLilyTile,
  hasChrysanthemumTile,
  hasBambooTile,
  isFlowerHand,
  isEightImmortalsCrossingTheSea,
  isRobbingKong,
  isWinByLastCatch,
  isWinByDoubleKong,
  isWinByKong,
  setBonusFaanDueToZeroExtraTiles,
  updateEightImmortalsCrossingTheSeaBoolean,
  updateFlowerHandBoolean,
  updateIsSelfPick,
  updateIsFullyConcealed,
  updateRoundWind,
  updateSeatWind,
  updateWinByLastCatchBoolean,
  updateRobbingKongBoolean,
  updateWinByDoubleKongBoolean,
  updateWinByKongBoolean,
  updateSettingOfBonusFaanDueToZeroExtraTiles,
}: Props) => {
  const extraTilesPossession = [
    hasSpringTile,
    hasSummerTile,
    hasAutumnTile,
    hasWinterTile,
    hasPlumTile,
    hasLilyTile,
    hasChrysanthemumTile,
    hasBambooTile,
  ]
  return (
    <BaseModal
      title={isChineseMode ? '追加番數' : 'Add Bonus'}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div>
        <p className="mt-8 text-base text-gray-500 dark:text-gray-300">
          {isChineseMode ? '圈風' : 'Round Winds'}
        </p>
        <WindOptions
          isChineseMode={isChineseMode}
          onChange={updateRoundWind}
          initValue={roundWind}
        />
      </div>

      <div>
        <p className="mt-8 text-base text-gray-500 dark:text-gray-300">
          {isChineseMode ? '門風' : 'Seat Winds'}
        </p>
        <WindOptions
          isChineseMode={isChineseMode}
          onChange={updateSeatWind}
          initValue={seatWind}
        />
      </div>

      <div>
        <p className="mt-8 text-base text-gray-500 dark:text-gray-300">
          {isChineseMode ? '花牌' : 'Extra Tiles'}
        </p>
        <FlowerOptions isChineseMode={isChineseMode} />

        {extraTilesPossession.filter((item) => item === true).length === 8 ? (
          <div>
            <p className="text-base text-gray-500 dark:text-gray-300">
              {isChineseMode ? '八仙過海' : 'Eight Immortals Crossing the Sea'}
            </p>
            <ToggleSwitch
              isChecked={isEightImmortalsCrossingTheSea}
              onChange={() => {
                updateEightImmortalsCrossingTheSeaBoolean()
              }}
              id="eightImmortalsCrossingTheSea"
            />
          </div>
        ) : (
          ''
        )}

        {extraTilesPossession.filter((item) => item === true).length === 7 ? (
          <div>
            <p className=" text-base text-gray-500 dark:text-gray-300">
              {isChineseMode ? '花糊' : 'Flower Hand'}
            </p>
            <ToggleSwitch
              isChecked={isFlowerHand}
              onChange={() => {
                updateFlowerHandBoolean()
              }}
              id="flowerHand"
            />
          </div>
        ) : (
          ''
        )}

        {extraTilesPossession.filter((item) => item === true).length === 0 ? (
          <div>
            <p className="text-base text-gray-500 dark:text-gray-300">
              {isChineseMode
                ? '無花加一番'
                : 'Bonus Faan value if having no Extra Tiles'}
            </p>
            <ToggleSwitch
              isChecked={setBonusFaanDueToZeroExtraTiles}
              onChange={() => {
                updateSettingOfBonusFaanDueToZeroExtraTiles()
              }}
              id="bonusFaanDueToZeroExtraTiles"
            />
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="mt-6 text-base text-gray-500 dark:text-gray-300">
            {isChineseMode ? SELF_PICK['chinese'] : SELF_PICK['english']}
          </p>
          <ToggleSwitch
            onChange={updateIsSelfPick}
            isChecked={isSelfPick}
            disabled={isRobbingKong}
            id={'selfPick'}
          />
        </div>
        <div>
          <p className="mt-6 text-base text-gray-500 dark:text-gray-300">
            {isChineseMode
              ? FULLY_CONCEALED['chinese']
              : FULLY_CONCEALED['english']}
          </p>
          <ToggleSwitch
            onChange={updateIsFullyConcealed}
            isChecked={isFullyConcealed}
            id={'fullyConcealed'}
          />
        </div>
        <div>
          <p className="mt-6 text-base text-gray-500 dark:text-gray-300">
            {isChineseMode ? '海底撈月' : 'Win By Last Catch'}
          </p>
          <ToggleSwitch
            onChange={updateWinByLastCatchBoolean}
            isChecked={isWinByLastCatch}
            disabled={isWinByDoubleKong || isWinByKong || isRobbingKong}
            id="winByLastCatch"
          />
        </div>
        <div>
          <p className="mt-6 text-base text-gray-500 dark:text-gray-300">
            {isChineseMode ? '搶槓' : 'Robbing Kong'}
          </p>
          <ToggleSwitch
            onChange={updateRobbingKongBoolean}
            isChecked={isRobbingKong}
            disabled={
              isWinByDoubleKong || isWinByKong || isWinByLastCatch || isSelfPick
            }
            id="robbingKong"
          />
        </div>
        <div>
          <p className="mt-6 text-base text-gray-500 dark:text-gray-300">
            {isChineseMode ? '槓上自摸' : 'Win By Kong'}
          </p>
          <ToggleSwitch
            onChange={updateWinByKongBoolean}
            isChecked={isWinByKong}
            disabled={isWinByDoubleKong || isRobbingKong || isWinByLastCatch}
            id="winByKong"
          />
        </div>
        <div>
          <p className="mt-6 text-base text-gray-500 dark:text-gray-300">
            {isChineseMode ? '槓上槓自摸' : 'Win By Double Kong'}
          </p>
          <ToggleSwitch
            onChange={updateWinByDoubleKongBoolean}
            isChecked={isWinByDoubleKong}
            disabled={isWinByKong || isRobbingKong || isWinByLastCatch}
            id="winByDoubleKong"
          />
        </div>
      </div>
    </BaseModal>
  )
}

export default connector(BonusValueModal)
