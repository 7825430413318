import * as actions from './actionTypes'

export const updateSeatWind = (input: string | null) => ({
  type: actions.UPDATE_SEAT_WIND,
  data: input,
})

export const updateRoundWind = (input: string | null) => ({
  type: actions.UPDATE_ROUND_WIND,
  data: input,
})

export const updateIsSelfPick = () => ({
  type: actions.UPDATE_IS_SELF_PICK,
})

export const updateIsFullyConcealed = () => ({
  type: actions.UPDATE_IS_FULLY_CONCEALED,
})

export const updateExtraTiles = (extraTileName: string) => ({
  type: actions.UPDATE_EXTRA_TILES,
  extraTileName,
})

export const resetBonusConfig = () => ({
  type: actions.RESET_BONUS_CONFIG,
})

export const updateFlowerHandBoolean = () => ({
  type: actions.UPDATE_FLOWER_HANDS_BOOLEAN,
})

export const updateWinByKongBoolean = () => ({
  type: actions.UPDATE_IS_WIN_BY_KONG,
})

export const updateWinByDoubleKongBoolean = () => ({
  type: actions.UPDATE_IS_WIN_BY_DOUBLE_KONG,
})

export const updateWinByLastCatchBoolean = () => ({
  type: actions.UPDATE_IS_WIN_BY_LAST_CATCH,
})

export const updateRobbingKongBoolean = () => ({
  type: actions.UPDATE_IS_ROBBING_KONG,
})

export const updateEightImmortalsCrossingTheSeaBoolean = () => ({
  type: actions.UPDATE_EIGHT_IMMORTALS_CROSSING_THE_SEA_BOOLEAN,
})

export const updateSettingOfBonusFaanDueToZeroExtraTiles = () => ({
  type: actions.UPDATE_SETTING_OF_BONUS_FAAN_DUE_TO_ZERO_EXTRA_TILES,
})
