import React from 'react'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isChineseMode: boolean
}

export const AboutModal = ({ isOpen, handleClose, isChineseMode }: Props) => {
  return (
    <BaseModal
      title={isChineseMode ? '關於' : 'About'}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {isChineseMode ? (
          <>
            <span>
              本網站建基於開源項目{' '}
              <span>
                {' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline font-bold"
                  href="https://github.com/yf-dev/mahjong-hand-guessing-game"
                >
                  mahjong-hand-guessing-game
                </a>
              </span>
              。
            </span>
          </>
        ) : (
          <>
            <span>
              This website is built upon the open source repository of{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="underline font-bold"
                href="https://github.com/yf-dev/mahjong-hand-guessing-game"
              >
                {' '}
                mahjong-hand-guessing-game
              </a>
            </span>
          </>
        )}
      </p>
    </BaseModal>
  )
}
