// import React, { useLayoutEffect } from 'react'
import React from 'react'
import {
  InformationCircleIcon,
  SunIcon,
  // CogIcon,
  TranslateIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Keyboard } from './components/keyboard/Keyboard'
import { DisclaimerModal } from './components/modals/DisclaimerModal'
import { InfoModal } from './components/modals/InfoModal'
import { AboutModal } from './components/modals/AboutModal'
import {
  ADD_BONUS,
  APP_TITLE,
  EAST,
  SOUTH,
  WEST,
  NORTH,
  FULLY_CONCEALED,
  INVALID_HAND_MESSAGE,
  SELF_PICK,
  SUBMIT,
  ROBBING_KONG,
  WIN_BY_LAST_CATCH,
  WIN_BY_KONG,
  WIN_BY_DOUBLE_KONG,
} from './constants/strings'
import './App.css'
import { FaanCalculator, Hand, Meld, Tile, WinningHand } from 'hk-mahjong'
import { CurrentRow } from './components/grid/CurrentRow'
import countNumberOfEmptyCells from './lib/countEmptyCells'
import { MeldType } from 'hk-mahjong/lib/meld/MeldType'
import { ResultModal } from './components/modals/ResultModal'
import BonusValueModal from './components/modals/BonusValueModal'
import { Tag } from './components/render/Tag'
import { BonusConfig, Winds } from './constants/types'
import { connect, ConnectedProps } from 'react-redux'
import {
  resetBonusConfig,
  updateIsSelfPick,
  updateIsFullyConcealed,
  updateSeatWind,
  updateRoundWind,
  updateExtraTiles,
  updateWinByLastCatchBoolean,
  updateRobbingKongBoolean,
  updateWinByDoubleKongBoolean,
  updateWinByKongBoolean,
  updateSettingOfBonusFaanDueToZeroExtraTiles,
} from './actions'
import FaanCalculationConfig from 'hk-mahjong/lib/calculateFaan/FaanCalculationConfig'
import InstallButton from './components/buttons/InstallButton'
import { getCurrentYear } from './lib/timeUtils'
// import { Link, useLocation } from 'react-router-dom'

const ALERT_TIME_MS = 8000

interface RootState {
  bonus: BonusConfig
}

const mapState = (state: RootState) => ({ ...state.bonus })
const mapDispatch = {
  resetBonusConfig,
  updateIsSelfPick,
  updateIsFullyConcealed,
  updateRoundWind,
  updateSeatWind,
  updateExtraTiles,
  updateWinByLastCatchBoolean,
  updateRobbingKongBoolean,
  updateWinByDoubleKongBoolean,
  updateWinByKongBoolean,
  updateSettingOfBonusFaanDueToZeroExtraTiles,
}

const connector = connect(mapState, mapDispatch)

type Props = ConnectedProps<typeof connector>

type UserInputMetadata = {
  input: (Tile | Meld)[]
  inputStringForm: string
}

function App({
  isSelfPick,
  isFullyConcealed,
  roundWind,
  seatWind,
  isFlowerHand,
  isEightImmortalsCrossingTheSea,
  hasSpringTile,
  hasSummerTile,
  hasAutumnTile,
  hasWinterTile,
  hasPlumTile,
  hasLilyTile,
  hasChrysanthemumTile,
  hasBambooTile,
  isWinByLastCatch,
  isRobbingKong,
  isWinByKong,
  isWinByDoubleKong,
  setBonusFaanDueToZeroExtraTiles,
  updateIsSelfPick,
  updateIsFullyConcealed,
  updateRoundWind,
  updateSeatWind,
  resetBonusConfig,
  updateExtraTiles,
  updateWinByLastCatchBoolean,
  updateRobbingKongBoolean,
  updateWinByDoubleKongBoolean,
  updateWinByKongBoolean,
  updateSettingOfBonusFaanDueToZeroExtraTiles,
}: Props) {
  // const location = useLocation()

  // useLayoutEffect(() => {
  //   document.documentElement.scrollTo(0, 0)
  // }, [location.pathname])

  const mapWinds = {
    east: EAST,
    south: SOUTH,
    west: WEST,
    north: NORTH,
  }

  const winningConfig: FaanCalculationConfig = {
    selfPick: isSelfPick,
    fullyConcealedHand: isFullyConcealed,
    extraTiles: {
      spring: hasSpringTile,
      summer: hasSummerTile,
      autumn: hasAutumnTile,
      winter: hasWinterTile,
      plum: hasPlumTile,
      lily: hasLilyTile,
      chrysanthemum: hasChrysanthemumTile,
      bamboo: hasBambooTile,
    },
    eightImmortalsCrossingTheSea: isEightImmortalsCrossingTheSea,
    flowersHand: isFlowerHand,
    robbingKong: isRobbingKong,
    winByDoubleKong: isWinByDoubleKong,
    winByKong: isWinByKong,
    winByLastCatch: isWinByLastCatch,
    enableBonusFaanDueToZeroExtraTile: setBonusFaanDueToZeroExtraTiles,
  }

  if (seatWind !== null) {
    winningConfig['seatWind'] = seatWind
  }

  if (roundWind !== null) {
    winningConfig['roundWind'] = roundWind
  }

  const produceTagsAboutExtraTiles = (
    input: boolean[],
    seatWind: null | Winds
  ) => {
    if (input.length !== 8) {
      return ''
    }

    const [spring, summer, autumn, winter, plum, lily, chrysanthemum, bamboo] =
      input
    if (
      spring &&
      summer &&
      autumn &&
      winter &&
      plum &&
      lily &&
      chrysanthemum &&
      bamboo &&
      isEightImmortalsCrossingTheSea
    ) {
      return (
        <Tag
          text={isChineseMode ? '八仙過海' : 'eight-immortals-crossing-the-sea'}
          delete={() => {
            const extraTiles = [
              'spring',
              'summer',
              'autumn',
              'winter',
              'plum',
              'lily',
              'chrysanthemum',
              'bamboo',
            ]
            extraTiles.forEach((tile) => updateExtraTiles(tile))
          }}
        />
      )
    }

    if (
      !spring &&
      !summer &&
      !autumn &&
      !winter &&
      !plum &&
      !lily &&
      !chrysanthemum &&
      !bamboo &&
      setBonusFaanDueToZeroExtraTiles
    ) {
      return (
        <Tag
          text={
            isChineseMode
              ? '無花加一番'
              : 'bonus-Faan-value-if-having-no-Extra-Tiles'
          }
          delete={() => {
            updateSettingOfBonusFaanDueToZeroExtraTiles()
          }}
        />
      )
    }

    const possession: string[] = []
    const extraTiles = [
      'spring',
      'summer',
      'autumn',
      'winter',
      'plum',
      'lily',
      'chrysanthemum',
      'bamboo',
    ]
    input.forEach((boolean, index) => {
      if (boolean === true) {
        possession.push(extraTiles[index])
      }
    })
    if (isFlowerHand) {
      return (
        <Tag
          text={isChineseMode ? '花糊' : 'flower-hand'}
          delete={() => {
            possession.forEach((tile) => updateExtraTiles(tile))
          }}
        />
      )
    }

    const result: JSX.Element[] = []

    const hasAllSeasons = spring && summer && autumn && winter
    if (hasAllSeasons && !isEightImmortalsCrossingTheSea && !isFlowerHand) {
      result.push(
        <Tag
          text={
            isChineseMode ? '一台花(春夏秋冬)' : 'complete-set-of-seasons-tiles'
          }
          delete={() => {
            const extraTiles = ['spring', 'summer', 'autumn', 'winter']
            extraTiles.forEach((tile) => updateExtraTiles(tile))
          }}
        />
      )
    }

    const hasAllFlowers = plum && lily && chrysanthemum && bamboo
    if (hasAllFlowers && !isEightImmortalsCrossingTheSea && !isFlowerHand) {
      result.push(
        <Tag
          text={
            isChineseMode ? '一台花(梅蘭菊竹)' : 'complete-set-of-flowers-tiles'
          }
          delete={() => {
            const extraTiles = ['plum', 'lily', 'chrysanthemum', 'bamboo']
            extraTiles.forEach((tile) => updateExtraTiles(tile))
          }}
        />
      )
    }

    if (seatWind === null) {
      return result
    }

    if (seatWind === 'east') {
      let numberOfMatchedExtraTiles = 0
      const possession: string[] = []
      if (spring && !hasAllSeasons) {
        numberOfMatchedExtraTiles++
        possession.push('spring')
      }

      if (plum && !hasAllFlowers) {
        numberOfMatchedExtraTiles++
        possession.push('plum')
      }
      if (numberOfMatchedExtraTiles > 0) {
        result.push(
          <Tag
            text={
              isChineseMode
                ? `${numberOfMatchedExtraTiles}隻正花`
                : `matched-extra-tiles(x${numberOfMatchedExtraTiles})`
            }
            delete={() => {
              possession.forEach((tile) => updateExtraTiles(tile))
            }}
          />
        )
      }
    } else if (seatWind === 'south') {
      let numberOfMatchedExtraTiles = 0
      const possession: string[] = []
      if (summer && !hasAllSeasons) {
        numberOfMatchedExtraTiles++
        possession.push('summer')
      }

      if (lily && !hasAllFlowers) {
        numberOfMatchedExtraTiles++
        possession.push('lily')
      }

      if (numberOfMatchedExtraTiles > 0) {
        result.push(
          <Tag
            text={
              isChineseMode
                ? `${numberOfMatchedExtraTiles}隻正花`
                : `matched-extra-tiles(x${numberOfMatchedExtraTiles})`
            }
            delete={() => {
              possession.forEach((tile) => updateExtraTiles(tile))
            }}
          />
        )
      }
    } else if (seatWind === 'west') {
      let numberOfMatchedExtraTiles = 0
      const possession: string[] = []
      if (autumn && !hasAllSeasons) {
        numberOfMatchedExtraTiles++
        possession.push('autumn')
      }

      if (chrysanthemum && !hasAllFlowers) {
        numberOfMatchedExtraTiles++
        possession.push('chrysanthemum')
      }
      if (numberOfMatchedExtraTiles > 0) {
        result.push(
          <Tag
            text={
              isChineseMode
                ? `${numberOfMatchedExtraTiles}隻正花`
                : `matched-extra-tiles(x${numberOfMatchedExtraTiles})`
            }
            delete={() => {
              possession.forEach((tile) => updateExtraTiles(tile))
            }}
          />
        )
      }
    } else if (seatWind === 'north') {
      let numberOfMatchedExtraTiles = 0
      const possession: string[] = []
      if (winter && !hasAllSeasons) {
        numberOfMatchedExtraTiles++
        possession.push('winter')
      }

      if (bamboo && !hasAllFlowers) {
        numberOfMatchedExtraTiles++
        possession.push('bamboo')
      }
      if (numberOfMatchedExtraTiles > 0) {
        result.push(
          <Tag
            text={
              isChineseMode
                ? `${numberOfMatchedExtraTiles}隻正花`
                : `matched-extra-tiles(x${numberOfMatchedExtraTiles})`
            }
            delete={() => {
              possession.forEach((tile) => updateExtraTiles(tile))
            }}
          />
        )
      }
    }

    return result
  }

  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const initUserInput = {
    input: [],
    inputStringForm: '',
  }
  const [userInput, setUserInput] = useState<UserInputMetadata>(initUserInput)
  const [isChineseMode, setIsChineseMode] = useState(
    localStorage.getItem('language')
      ? localStorage.getItem('language') === 'zh'
      : true
  )
  FaanCalculator.setLanguage(isChineseMode ? 'zh' : 'en')

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isResultModalOpen, setIsResultModalOpen] = useState(false)
  const [isBonusValueModalOpen, setIsBonusValueModalOpen] = useState(false)
  const [isInvalidHandAlertOpen, setIsInvalidHandAlertOpen] = useState(false)
  const [winningHands, setWinningHands] = useState<Array<WinningHand | Hand>>(
    []
  )
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )

  const countFaanValue = () => {
    const tiles: Tile[] = []
    const melds: Meld[] = []
    for (const item of userInput.input) {
      if (item instanceof Tile) {
        tiles.push(item)
      } else {
        melds.push(item)
      }
    }
    try {
      const handsFound: (Hand | WinningHand)[] = []
      const hand = new Hand({ melds, tiles })
      if (isEightImmortalsCrossingTheSea || isFlowerHand) {
        handsFound.push(hand)
      } else {
        hand
          .findAllWinningPermutations()
          .filter((winningHand) => {
            const { value } = FaanCalculator.calculate(
              winningHand,
              winningConfig
            )
            return (
              value === '∞' || value >= FaanCalculator.getThresholdFaanValue()
            )
          })
          .forEach((handFound) => handsFound.push(handFound))
      }

      if (handsFound.length > 0) {
        setWinningHands(handsFound)
        setIsInvalidHandAlertOpen(false)
        setIsReportIssueMode(false)
        setUserFeedback('')
        setHasSubmittedFeedback(false)

        setIsResultModalOpen(true)
      } else {
        setIsInvalidHandAlertOpen(true)
      }
    } catch (err) {
      setIsInvalidHandAlertOpen(true)
    }
  }

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleLanguageMode = (isChinese: boolean) => {
    setIsChineseMode(isChinese)
    localStorage.setItem('language', isChinese ? 'zh' : 'en')
  }

  useEffect(() => {
    if (isInvalidHandAlertOpen) {
      setTimeout(() => {
        setIsInvalidHandAlertOpen(false)
      }, ALERT_TIME_MS)
    }
  }, [isInvalidHandAlertOpen])

  const onChar = (input: Tile | Meld) => {
    const latestUserInput = [...userInput['input']]
    latestUserInput.push(input)
    const latestInputStringForm =
      userInput['inputStringForm'] + input.toString()
    setUserInput({
      input: latestUserInput,
      inputStringForm: latestInputStringForm,
    })
  }

  const onChars = (input: Tile[]) => {
    const latestUserInput = [...userInput['input']]
    let latestInputStringForm = userInput['inputStringForm']

    for (const tile of input) {
      latestUserInput.push(tile)
      latestInputStringForm += tile.toString()
    }

    setUserInput({
      input: latestUserInput,
      inputStringForm: latestInputStringForm,
    })
  }

  const onDelete = () => {
    const latestUserInput = [...userInput['input']]
    latestUserInput.pop()

    let latestUserInputStringForm = ''
    for (const item of latestUserInput) {
      latestUserInputStringForm += item.toString()
    }

    setUserInput({
      input: latestUserInput,
      inputStringForm: latestUserInputStringForm,
    })
  }

  const reset = () => {
    setUserInput(initUserInput)
    resetBonusConfig()
  }

  const hasFormedEyesPair = (input: (Tile | Meld)[]) => {
    let result = false
    for (const item of input) {
      if (item instanceof Meld && item.getMeldType() === MeldType.EYES) {
        result = true
      }
    }
    return result
  }

  const [isReportIssueMode, setIsReportIssueMode] = useState(false)

  const [userFeedback, setUserFeedback] = useState('')
  const [hasSubmittedFeedback, setHasSubmittedFeedback] = useState(false)

  return (
    <div className="py-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-90 px-4 justify-between items-center mb-8 mt-12">
        <div>
          <h1 className="text-xl grow font-bold dark:text-white font-bold">
            {isChineseMode ? APP_TITLE['chinese'] : APP_TITLE['english']}
          </h1>
          <div className="mt-2">
            {/* <span className="border-2 text-rose-500 border-rose-500 dark:bg-white rounded-full text-sm font-medium px-2 py-1">
              {isChineseMode ? '測試中' : 'Beta'}
            </span> */}
          </div>
        </div>
        <div className="flex justify-center">
          <SunIcon
            className="mx-2 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => handleDarkMode(!isDarkMode)}
          />
          {/* <CogIcon className="h-6 w-6 cursor-pointer dark:stroke-white" /> */}
          <TranslateIcon
            className="mx-2 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => {
              handleLanguageMode(!isChineseMode)
            }}
          />
          <InformationCircleIcon
            className="mx-2 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
          <InstallButton />
        </div>
      </div>
      <div className="pb-6">
        <CurrentRow input={userInput['input']} />
      </div>
      <Keyboard
        hideAlert={() => {
          setIsInvalidHandAlertOpen(false)
        }}
        onChar={onChar}
        onDelete={onDelete}
        onClearAll={reset}
        onChars={onChars}
        tilesInput={userInput['inputStringForm']}
        tilesInputData={userInput['input']}
        disableAllInputKeys={countNumberOfEmptyCells(userInput['input']) <= 0}
        disableEyeCommandKey={hasFormedEyesPair(userInput['input'])}
        isChineseMode={isChineseMode}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
        isChineseMode={isChineseMode}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
        isChineseMode={isChineseMode}
      />
      <ResultModal
        isOpen={isResultModalOpen}
        winningHands={winningHands}
        handleClose={() => {
          setIsResultModalOpen(false)
        }}
        isChineseMode={isChineseMode}
        isReportIssueMode={isReportIssueMode}
        setIsReportIssueMode={setIsReportIssueMode}
        userFeedback={userFeedback}
        setUserFeedback={setUserFeedback}
        hasSubmittedFeedback={hasSubmittedFeedback}
        setHasSubmittedFeedback={setHasSubmittedFeedback}
      />
      <BonusValueModal
        isOpen={isBonusValueModalOpen}
        handleClose={() => setIsBonusValueModalOpen(false)}
        isChineseMode={isChineseMode}
      />
      <DisclaimerModal
        isOpen={isDisclaimerModalOpen}
        handleClose={() => setIsDisclaimerModalOpen(false)}
        isChineseMode={isChineseMode}
      />

      <button
        type="button"
        className="
        mx-auto mt-8 flex items-center 
        px-2.5 py-1.5 
        text-black bg-slate-200 hover:bg-slate-300 active:bg-slate-400        
        border border-transparent text-base font-medium rounded         
        hover:ring-2 hover:ring-offset-2
        hover:outline-none hover:ring-slate-700 
        select-none"
        id="addBonus"
        onClick={() => {
          setIsBonusValueModalOpen(true)
        }}
      >
        {isChineseMode ? ADD_BONUS['chinese'] : ADD_BONUS['english']}
      </button>
      <div className="flex flex-wrap items-center justify-center mt-2">
        {isSelfPick ? (
          <Tag
            text={
              isChineseMode
                ? SELF_PICK['chinese']
                : SELF_PICK['english'].toLowerCase()
            }
            delete={updateIsSelfPick}
          />
        ) : (
          ''
        )}
        {isFullyConcealed ? (
          <Tag
            text={
              isChineseMode
                ? FULLY_CONCEALED['chinese']
                : FULLY_CONCEALED['english'].toLowerCase().split(' ').join('-')
            }
            delete={updateIsFullyConcealed}
          />
        ) : (
          ''
        )}
        {roundWind !== null ? (
          <Tag
            text={
              isChineseMode
                ? mapWinds[roundWind]['chinese'] + '圈'
                : mapWinds[roundWind]['english'].toLowerCase() + '-round'
            }
            delete={() => {
              updateRoundWind(null)
            }}
          />
        ) : (
          ''
        )}
        {seatWind !== null ? (
          <Tag
            text={
              isChineseMode
                ? mapWinds[seatWind]['chinese'] + '位'
                : mapWinds[seatWind]['english'].toLowerCase() + '-seat'
            }
            delete={() => {
              updateSeatWind(null)
            }}
          />
        ) : (
          ''
        )}
        {isWinByLastCatch !== false ? (
          <Tag
            text={
              isChineseMode
                ? WIN_BY_LAST_CATCH['chinese']
                : WIN_BY_LAST_CATCH['english']
                    .toLowerCase()
                    .split(' ')
                    .join('-')
            }
            delete={updateWinByLastCatchBoolean}
          />
        ) : (
          ''
        )}
        {isRobbingKong !== false ? (
          <Tag
            text={
              isChineseMode
                ? ROBBING_KONG['chinese']
                : ROBBING_KONG['english'].toLowerCase().split(' ').join('-')
            }
            delete={updateRobbingKongBoolean}
          />
        ) : (
          ''
        )}
        {isWinByKong !== false ? (
          <Tag
            text={
              isChineseMode
                ? WIN_BY_KONG['chinese']
                : WIN_BY_KONG['english'].toLowerCase().split(' ').join('-')
            }
            delete={updateWinByKongBoolean}
          />
        ) : (
          ''
        )}
        {isWinByDoubleKong !== false ? (
          <Tag
            text={
              isChineseMode
                ? WIN_BY_DOUBLE_KONG['chinese']
                : WIN_BY_DOUBLE_KONG['english']
                    .toLowerCase()
                    .split(' ')
                    .join('-')
            }
            delete={updateWinByDoubleKongBoolean}
          />
        ) : (
          ''
        )}
        {produceTagsAboutExtraTiles(
          [
            hasSpringTile,
            hasSummerTile,
            hasAutumnTile,
            hasWinterTile,
            hasPlumTile,
            hasLilyTile,
            hasChrysanthemumTile,
            hasBambooTile,
          ],
          seatWind
        )}
      </div>

      <button
        type="button"
        className="
        mx-auto mt-8 flex items-center 
        px-2.5 py-1.5 
        border border-transparent text-base font-medium rounded 
        text-indigo-700 bg-indigo-100 dark:text-sky-700 dark:bg-sky-100 
        hover:ring-2 hover:ring-offset-2
        hover:bg-indigo-200 hover:outline-none hover:ring-indigo-500 
        dark:hover:bg-sky-800 dark:hover:outline-none  dark:hover:ring-sky-800 dark:hover:text-white
        select-none"
        onClick={countFaanValue}
        id="submit"
      >
        {isChineseMode ? SUBMIT['chinese'] : SUBMIT['english']}
      </button>
      <div className="flex justify-center mt-24 text-xs text-gray-300">
        <span className="mr-2">© {getCurrentYear()}</span>
        <span>|</span>
        <span
          className="underline mx-2 cursor-pointer"
          onClick={() => {
            setIsDisclaimerModalOpen(true)
          }}
        >
          {isChineseMode ? '免責聲明' : 'Disclaimer'}
        </span>
        {/* <span>|</span>
        <Link
          to={`privacy-policy`}
          style={{ textDecoration: 'none', color: 'none' }}
        >
          <span className="underline mx-2 cursor-pointer">
            {isChineseMode ? '隱私權' : 'Privacy'}
          </span>
        </Link> */}
      </div>
      <Alert
        message={
          isChineseMode
            ? INVALID_HAND_MESSAGE['chinese']
            : INVALID_HAND_MESSAGE['english']
        }
        isOpen={isInvalidHandAlertOpen}
      />
    </div>
  )
}

export default connector(App)
