import { BonusConfig } from '../constants/types'
import * as actions from '../actions/actionTypes'

const initState: BonusConfig = {
  roundWind: null,
  seatWind: null,
  isSelfPick: false,
  isFullyConcealed: false,
  hasSpringTile: false,
  hasSummerTile: false,
  hasAutumnTile: false,
  hasWinterTile: false,
  hasPlumTile: false,
  hasLilyTile: false,
  hasChrysanthemumTile: false,
  hasBambooTile: false,
  isFlowerHand: false,
  isEightImmortalsCrossingTheSea: false,
  isWinByLastCatch: false,
  isRobbingKong: false,
  isWinByKong: false,
  isWinByDoubleKong: false,
  setBonusFaanDueToZeroExtraTiles: false,
}

const bonus = (
  state = initState,
  action: {
    type: string
    data?: string
    newSelfPick?: boolean
    newFullyConcealed?: boolean
    extraTileName?: string
  }
) => {
  switch (action.type) {
    case actions.UPDATE_IS_SELF_PICK: {
      const newWinByLastCatch =
        !state.isSelfPick === false ? false : state.isWinByLastCatch
      const newWinByKong =
        !state.isSelfPick === false ? false : state.isWinByKong
      const newWinByDoubleKong =
        !state.isSelfPick === false ? false : state.isWinByDoubleKong
      return {
        ...state,
        isSelfPick: !state.isSelfPick,
        isWinByLastCatch: newWinByLastCatch,
        isWinByKong: newWinByKong,
        isWinByDoubleKong: newWinByDoubleKong,
      }
    }

    case actions.UPDATE_IS_FULLY_CONCEALED:
      return {
        ...state,
        isFullyConcealed: !state.isFullyConcealed,
      }

    case actions.UPDATE_ROUND_WIND:
      return {
        ...state,
        roundWind: action.data,
      }

    case actions.UPDATE_SEAT_WIND:
      return {
        ...state,
        seatWind: action.data,
      }
    case actions.UPDATE_EXTRA_TILES:
      // const newSettingBonusFaanDueToZeroExtraTiles = false;
      if (action.extraTileName === undefined) {
        return { ...state }
      } else if (action.extraTileName === 'spring') {
        const newIsEightImmortalsCrossingTheSea =
          !state.hasSpringTile &&
          state.hasSummerTile &&
          state.hasAutumnTile &&
          state.hasWinterTile &&
          state.hasPlumTile &&
          state.hasLilyTile &&
          state.hasChrysanthemumTile &&
          state.hasBambooTile
            ? state.isEightImmortalsCrossingTheSea
            : false

        const newIsFlowerHand =
          [
            !state.hasSpringTile,
            state.hasSummerTile,
            state.hasAutumnTile,
            state.hasWinterTile,
            state.hasPlumTile,
            state.hasLilyTile,
            state.hasChrysanthemumTile,
            state.hasBambooTile,
          ].filter((item) => item === true).length === 7
            ? state.isFlowerHand
            : false

        return {
          ...state,
          hasSpringTile: !state.hasSpringTile,
          isFlowerHand: newIsFlowerHand,
          isEightImmortalsCrossingTheSea: newIsEightImmortalsCrossingTheSea,
        }
      } else if (action.extraTileName === 'summer') {
        const newIsEightImmortalsCrossingTheSea =
          state.hasSpringTile &&
          !state.hasSummerTile &&
          state.hasAutumnTile &&
          state.hasWinterTile &&
          state.hasPlumTile &&
          state.hasLilyTile &&
          state.hasChrysanthemumTile &&
          state.hasBambooTile
            ? state.isEightImmortalsCrossingTheSea
            : false

        const newIsFlowerHand =
          [
            state.hasSpringTile,
            !state.hasSummerTile,
            state.hasAutumnTile,
            state.hasWinterTile,
            state.hasPlumTile,
            state.hasLilyTile,
            state.hasChrysanthemumTile,
            state.hasBambooTile,
          ].filter((item) => item === true).length === 7
            ? state.isFlowerHand
            : false
        return {
          ...state,
          hasSummerTile: !state.hasSummerTile,
          isFlowerHand: newIsFlowerHand,
          isEightImmortalsCrossingTheSea: newIsEightImmortalsCrossingTheSea,
        }
      } else if (action.extraTileName === 'autumn') {
        const newIsEightImmortalsCrossingTheSea =
          state.hasSpringTile &&
          state.hasSummerTile &&
          !state.hasAutumnTile &&
          state.hasWinterTile &&
          state.hasPlumTile &&
          state.hasLilyTile &&
          state.hasChrysanthemumTile &&
          state.hasBambooTile
            ? state.isEightImmortalsCrossingTheSea
            : false

        const newIsFlowerHand =
          [
            state.hasSpringTile,
            state.hasSummerTile,
            !state.hasAutumnTile,
            state.hasWinterTile,
            state.hasPlumTile,
            state.hasLilyTile,
            state.hasChrysanthemumTile,
            state.hasBambooTile,
          ].filter((item) => item === true).length === 7
            ? state.isFlowerHand
            : false
        return {
          ...state,
          hasAutumnTile: !state.hasAutumnTile,
          isFlowerHand: newIsFlowerHand,
          isEightImmortalsCrossingTheSea: newIsEightImmortalsCrossingTheSea,
        }
      } else if (action.extraTileName === 'winter') {
        const newIsEightImmortalsCrossingTheSea =
          state.hasSpringTile &&
          state.hasSummerTile &&
          state.hasAutumnTile &&
          !state.hasWinterTile &&
          state.hasPlumTile &&
          state.hasLilyTile &&
          state.hasChrysanthemumTile &&
          state.hasBambooTile
            ? state.isEightImmortalsCrossingTheSea
            : false

        const newIsFlowerHand =
          [
            state.hasSpringTile,
            state.hasSummerTile,
            state.hasAutumnTile,
            !state.hasWinterTile,
            state.hasPlumTile,
            state.hasLilyTile,
            state.hasChrysanthemumTile,
            state.hasBambooTile,
          ].filter((item) => item === true).length === 7
            ? state.isFlowerHand
            : false
        return {
          ...state,
          hasWinterTile: !state.hasWinterTile,
          isFlowerHand: newIsFlowerHand,
          isEightImmortalsCrossingTheSea: newIsEightImmortalsCrossingTheSea,
        }
      } else if (action.extraTileName === 'plum') {
        const newIsEightImmortalsCrossingTheSea =
          state.hasSpringTile &&
          state.hasSummerTile &&
          state.hasAutumnTile &&
          state.hasWinterTile &&
          !state.hasPlumTile &&
          state.hasLilyTile &&
          state.hasChrysanthemumTile &&
          state.hasBambooTile
            ? state.isEightImmortalsCrossingTheSea
            : false

        const newIsFlowerHand =
          [
            state.hasSpringTile,
            state.hasSummerTile,
            state.hasAutumnTile,
            state.hasWinterTile,
            !state.hasPlumTile,
            state.hasLilyTile,
            state.hasChrysanthemumTile,
            state.hasBambooTile,
          ].filter((item) => item === true).length === 7
            ? state.isFlowerHand
            : false

        return {
          ...state,
          hasPlumTile: !state.hasPlumTile,
          isFlowerHand: newIsFlowerHand,
          isEightImmortalsCrossingTheSea: newIsEightImmortalsCrossingTheSea,
        }
      } else if (action.extraTileName === 'lily') {
        const newIsEightImmortalsCrossingTheSea =
          state.hasSpringTile &&
          state.hasSummerTile &&
          state.hasAutumnTile &&
          state.hasWinterTile &&
          state.hasPlumTile &&
          !state.hasLilyTile &&
          state.hasChrysanthemumTile &&
          state.hasBambooTile
            ? state.isFlowerHand
            : false

        const newIsFlowerHand =
          [
            state.hasSpringTile,
            state.hasSummerTile,
            state.hasAutumnTile,
            state.hasWinterTile,
            state.hasPlumTile,
            !state.hasLilyTile,
            state.hasChrysanthemumTile,
            state.hasBambooTile,
          ].filter((item) => item === true).length === 7
            ? state.isFlowerHand
            : false

        return {
          ...state,
          hasLilyTile: !state.hasLilyTile,
          isFlowerHand: newIsFlowerHand,
          isEightImmortalsCrossingTheSea: newIsEightImmortalsCrossingTheSea,
        }
      } else if (action.extraTileName === 'chrysanthemum') {
        const newIsEightImmortalsCrossingTheSea =
          state.hasSpringTile &&
          state.hasSummerTile &&
          state.hasAutumnTile &&
          state.hasWinterTile &&
          state.hasPlumTile &&
          state.hasLilyTile &&
          !state.hasChrysanthemumTile &&
          state.hasBambooTile
            ? state.isEightImmortalsCrossingTheSea
            : false

        const newIsFlowerHand =
          [
            !state.hasSpringTile,
            state.hasSummerTile,
            state.hasAutumnTile,
            state.hasWinterTile,
            state.hasPlumTile,
            state.hasLilyTile,
            !state.hasChrysanthemumTile,
            state.hasBambooTile,
          ].filter((item) => item === true).length === 7
            ? state.isFlowerHand
            : false

        return {
          ...state,
          hasChrysanthemumTile: !state.hasChrysanthemumTile,
          isFlowerHand: newIsFlowerHand,
          isEightImmortalsCrossingTheSea: newIsEightImmortalsCrossingTheSea,
        }
      } else if (action.extraTileName === 'bamboo') {
        const newIsEightImmortalsCrossingTheSea =
          state.hasSpringTile &&
          state.hasSummerTile &&
          state.hasAutumnTile &&
          state.hasWinterTile &&
          state.hasPlumTile &&
          state.hasLilyTile &&
          state.hasChrysanthemumTile &&
          !state.hasBambooTile
            ? state.isEightImmortalsCrossingTheSea
            : false

        const newIsFlowerHand =
          [
            !state.hasSpringTile,
            state.hasSummerTile,
            state.hasAutumnTile,
            state.hasWinterTile,
            state.hasPlumTile,
            state.hasLilyTile,
            state.hasChrysanthemumTile,
            !state.hasBambooTile,
          ].filter((item) => item === true).length === 7
            ? state.isFlowerHand
            : false
        return {
          ...state,
          hasBambooTile: !state.hasBambooTile,
          isFlowerHand: newIsFlowerHand,
          isEightImmortalsCrossingTheSea: newIsEightImmortalsCrossingTheSea,
        }
      } else {
        return { ...state }
      }

    case actions.UPDATE_FLOWER_HANDS_BOOLEAN:
      return {
        ...state,
        isFlowerHand: !state.isFlowerHand,
      }

    case actions.UPDATE_EIGHT_IMMORTALS_CROSSING_THE_SEA_BOOLEAN:
      return {
        ...state,
        isEightImmortalsCrossingTheSea: !state.isEightImmortalsCrossingTheSea,
      }

    case actions.UPDATE_IS_WIN_BY_LAST_CATCH: {
      const newIsSelfPick =
        !state.isWinByLastCatch === true ? true : state.isSelfPick
      return {
        ...state,
        isWinByLastCatch: !state.isWinByLastCatch,
        isSelfPick: newIsSelfPick,
      }
    }

    case actions.UPDATE_IS_ROBBING_KONG: {
      const newIsSelfPick =
        !state.isRobbingKong === true ? false : state.isSelfPick
      return {
        ...state,
        isRobbingKong: !state.isRobbingKong,
        isSelfPick: newIsSelfPick,
      }
    }
    case actions.UPDATE_IS_WIN_BY_KONG: {
      const newIsSelfPick =
        !state.isWinByKong === true ? true : state.isSelfPick
      return {
        ...state,
        isWinByKong: !state.isWinByKong,
        isWinByDoubleKong: false,
        isSelfPick: newIsSelfPick,
      }
    }
    case actions.UPDATE_IS_WIN_BY_DOUBLE_KONG: {
      const newIsSelfPick =
        !state.isWinByDoubleKong === true ? true : state.isSelfPick
      return {
        ...state,
        isWinByDoubleKong: !state.isWinByDoubleKong,
        isWinByKong: false,
        isSelfPick: newIsSelfPick,
      }
    }

    case actions.UPDATE_SETTING_OF_BONUS_FAAN_DUE_TO_ZERO_EXTRA_TILES: {
      return {
        ...state,
        setBonusFaanDueToZeroExtraTiles: !state.setBonusFaanDueToZeroExtraTiles,
      }
    }

    case actions.RESET_BONUS_CONFIG:
      return {
        ...initState,
      }
    default:
      return state
  }
}

export default bonus
