import React from 'react'
import { CharStatus } from '../../lib/statuses'
import { RenderTile } from '../render/Render'

type Props = {
  value?: string
  status?: CharStatus
}

export const Cell = ({ value }: Props) => {
  return (
    <div className="md:w-12 sm:w-9 w-6 md:h-16 sm:h-12 h-8 border-solid md:border-2 border flex items-center justify-center sm:mx-0.5 md:text-4xl sm:text-2xl text-xl rounded bg-white">
      <RenderTile tile={value} />
    </div>
  )
}
