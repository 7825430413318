export type BilingualTerm = {
  chinese: string
  english: string
}

export const APP_TITLE: BilingualTerm = {
  chinese: '香港麻雀計番器',
  english: 'HK Mahjong Calculator',
}
export const INVALID_HAND_MESSAGE: BilingualTerm = {
  chinese: '詐糊 / 輸入錯誤 (註: 3番起糊)',
  english:
    'Invalid Hand (Remarks: A Winning Hand should have a minimum Faan value of 3)',
}

export const KONG: BilingualTerm = {
  chinese: '槓',
  english: 'Kong',
}

export const PONG: BilingualTerm = {
  chinese: '碰',
  english: 'Pong',
}

export const CHOW: BilingualTerm = {
  chinese: '上',
  english: 'Chow',
}

export const FORM_EYES: BilingualTerm = {
  chinese: '做眼',
  english: 'Eyes',
}

export const THIRTEEN_ORPHANS: BilingualTerm = {
  chinese: '13 么',
  english: '13 Orphans',
}

export const ADD_BONUS: BilingualTerm = {
  chinese: '追加番數',
  english: 'Add Bonus',
}

export const SELF_PICK: BilingualTerm = {
  chinese: '自摸',
  english: 'Self-pick',
}

export const FULLY_CONCEALED: BilingualTerm = {
  chinese: '門前清',
  english: 'Fully Concealed',
}

export const ROBBING_KONG: BilingualTerm = {
  chinese: '搶槓',
  english: 'Robbing Kong',
}

export const WIN_BY_LAST_CATCH: BilingualTerm = {
  chinese: '海底撈月',
  english: 'Win By Last Catch',
}

export const WIN_BY_KONG: BilingualTerm = {
  chinese: '槓上自摸',
  english: 'Win By Kong',
}

export const WIN_BY_DOUBLE_KONG: BilingualTerm = {
  chinese: '槓上槓自摸',
  english: 'Win By Double Kong',
}

export const HEAVENLY_HAND: BilingualTerm = {
  chinese: '天糊',
  english: 'Heavenly Hand',
}

export const EARTHLY_HAND: BilingualTerm = {
  chinese: '地糊',
  english: 'Earthly Hand',
}

export const SUBMIT: BilingualTerm = {
  chinese: '確定',
  english: 'Submit',
}

export const EAST: BilingualTerm = {
  chinese: '東',
  english: 'east',
}

export const SOUTH: BilingualTerm = {
  chinese: '南',
  english: 'south',
}

export const WEST: BilingualTerm = {
  chinese: '西',
  english: 'west',
}

export const NORTH: BilingualTerm = {
  chinese: '北',
  english: 'north',
}

export const SPRING: BilingualTerm = {
  chinese: '🀦',
  english: 'spring',
}

export const SUMMER: BilingualTerm = {
  chinese: '🀧',
  english: 'summer',
}

export const AUTUMN: BilingualTerm = {
  chinese: '🀨',
  english: 'autumn',
}

export const WINTER: BilingualTerm = {
  chinese: '🀩',
  english: 'winter',
}

export const PLUM: BilingualTerm = {
  chinese: '🀢',
  english: 'plum',
}

export const LILY: BilingualTerm = {
  chinese: '🀣',
  english: 'lily',
}

export const CHRYSANTHEMUM: BilingualTerm = {
  chinese: '🀥',
  english: 'chrysanthemum',
}

export const BAMBOO: BilingualTerm = {
  chinese: '🀤',
  english: 'bamboo',
}
