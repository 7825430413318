import React from 'react'
import { Cell } from './Cell'
import { Meld, Tile } from 'hk-mahjong'
import countNumberOfEmptyCells from '../../lib/countEmptyCells'
import { v4 as uuidv4 } from 'uuid'
import { MeldCell } from './MeldCell'
import { useWindowSize } from '../../lib/useWindowSize'

export function displayTiles(input: Array<Tile | Meld>) {
  const result: JSX.Element[] = []
  for (const item of input) {
    if (item instanceof Tile) {
      const tileSymbol = item.toString()
      result.push(
        <div className="py-2 my-1" key={uuidv4()}>
          <Cell value={tileSymbol} />
        </div>
      )
    } else {
      result.push(<MeldCell key={uuidv4()} tiles={item.toString()} />)
    }
  }
  return result
}

export const CurrentRow = (props: { input: Array<Tile | Meld> }) => {
  const numberOfEmptyCells = countNumberOfEmptyCells(props.input)
  const { width } = useWindowSize()
  return (
    <div className="flex justify-center flex-wrap">
      {displayTiles(props.input)}
      {width > 350 ? (
        <div className="py-2 my-1 flex flex-wrap justify-center">
          {numberOfEmptyCells > 0
            ? [...new Array(numberOfEmptyCells)].map(() => (
                <Cell key={uuidv4()} />
              ))
            : ''}
        </div>
      ) : (
        <div className="py-2 my-1 flex flex-wrap justify-center">
          {numberOfEmptyCells > 0 ? (
            numberOfEmptyCells === 14 ? (
              <>
                <div className="py-2 my-1 flex flex-wrap justify-center">
                  {[...new Array(7)].map(() => (
                    <Cell key={uuidv4()} />
                  ))}
                </div>
                <div className="py-2 my-1 flex flex-wrap justify-center">
                  {' '}
                  {[...new Array(7)].map(() => (
                    <Cell key={uuidv4()} />
                  ))}
                </div>
              </>
            ) : (
              [...new Array(numberOfEmptyCells)].map(() => (
                <Cell key={uuidv4()} />
              ))
            )
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  )
}
