import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  SPRING,
  SUMMER,
  AUTUMN,
  WINTER,
  PLUM,
  LILY,
  CHRYSANTHEMUM,
  BAMBOO,
} from '../../constants/strings'
import { connect, ConnectedProps } from 'react-redux'
import { updateExtraTiles } from '../../actions'
import { BonusConfig } from '../../constants/types'

interface RootState {
  bonus: BonusConfig
}

const mapState = (state: RootState) => ({ ...state.bonus })
const mapDispatch = {
  updateExtraTiles,
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  isChineseMode: boolean
}

const seasons = [SPRING, SUMMER, AUTUMN, WINTER]

const flowers = [PLUM, LILY, CHRYSANTHEMUM, BAMBOO]

const tiles = seasons.concat(flowers)

function FlowerOptions({
  isChineseMode,
  updateExtraTiles,
  hasSpringTile,
  hasSummerTile,
  hasAutumnTile,
  hasWinterTile,
  hasPlumTile,
  hasLilyTile,
  hasChrysanthemumTile,
  hasBambooTile,
}: Props) {
  const booleans = [
    hasSpringTile,
    hasSummerTile,
    hasAutumnTile,
    hasWinterTile,
    hasPlumTile,
    hasLilyTile,
    hasChrysanthemumTile,
    hasBambooTile,
  ]

  return (
    <div className="w-full px-1 py-2">
      <div className="w-full max-w-md mx-auto">
        <div className="inline-block">
          {isChineseMode ? (
            <div className="inline-block grid sm:grid-cols-4 grid-cols-2 ">
              {tiles.map((tiles, index) => (
                <div
                  key={uuidv4()}
                  onClick={() => {
                    updateExtraTiles(tiles['english'])
                  }}
                >
                  <div
                    className={`${
                      booleans[index]
                        ? 'bg-sky-900 dark:bg-amber-500 bg-opacity-75 dark:text-slate-800 text-slate-100'
                        : 'bg-white'
                    }
                      px-1 pb-4 relative rounded-lg shadow-md  my-2 cursor-pointer inline-flex mx-2 focus:outline-none justify-center`}
                  >
                    <div className="text-5xl">{tiles['chinese']}</div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="inline-block grid grid-cols-12 gap-3">
              {tiles.map((tiles, index) => (
                <div
                  className={
                    tiles['english'] !== 'chrysanthemum'
                      ? tiles['english'] === 'lily' ||
                        tiles['english'] === 'plum'
                        ? 'sm:col-span-3 col-span-6'
                        : 'sm:col-span-3 col-span-6'
                      : 'sm:col-span-5 col-span-12'
                  }
                  key={uuidv4()}
                  onClick={() => {
                    updateExtraTiles(tiles['english'])
                  }}
                >
                  <div
                    className={`${
                      booleans[index]
                        ? 'bg-sky-900 dark:bg-amber-500 bg-opacity-75 dark:text-slate-800 text-slate-100'
                        : 'bg-white'
                    } w-full px-5 py-4 relative rounded-lg shadow-md  my-2 cursor-pointer inline-flex mx-2 focus:outline-none justify-center`}
                  >
                    <div className="text-base mx-auto flex justify-center items-center">
                      <span className="text-4xl mr-1 pb-2">
                        {tiles['chinese']}
                      </span>
                      <span>{tiles['english']}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default connector(FlowerOptions)
