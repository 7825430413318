import React, { useState } from 'react'
import { BackspaceIcon, RefreshIcon } from '@heroicons/react/outline'
import { Commands, KeyValue } from '../../lib/keyboard'
import { Key } from './Key'
import {
  KONG,
  PONG,
  CHOW,
  FORM_EYES,
  THIRTEEN_ORPHANS,
} from '../../constants/strings'
import { Meld, Tile } from 'hk-mahjong'
import GraphemeSplitter from 'grapheme-splitter'
import { v4 as uuidv4 } from 'uuid'
import { useWindowSize } from '../../lib/useWindowSize'
import { MeldType } from 'hk-mahjong/lib/meld/MeldType'

type Props = {
  onChar: (value: Tile | Meld) => void
  onDelete: () => void
  onClearAll: () => void
  onChars: (input: Tile[]) => void
  tilesInput: string
  tilesInputData: (Tile | Meld)[]
  disableAllInputKeys: boolean
  disableEyeCommandKey: boolean
  isChineseMode: boolean
  hideAlert: () => void
}

function tilesCounter(str: string) {
  const graphemeSplitter = new GraphemeSplitter()
  const tiles = graphemeSplitter.splitGraphemes(str)
  return tiles.length
}

const characters: KeyValue[] = ['🀇', '🀈', '🀉', '🀊', '🀋', '🀌', '🀍', '🀎', '🀏']
const dots: KeyValue[] = ['🀙', '🀚', '🀛', '🀜', '🀝', '🀞', '🀟', '🀠', '🀡']
const bamboos: KeyValue[] = ['🀐', '🀑', '🀒', '🀓', '🀔', '🀕', '🀖', '🀗', '🀘']
const bonus: KeyValue[] = ['🀀', '🀁', '🀂', '🀃', '🀄', '🀅', '🀆']
const occurrenceLimit = 4

export const Keyboard = ({
  onChar,
  onDelete,
  onClearAll,
  onChars,
  tilesInput,
  disableAllInputKeys,
  tilesInputData,
  disableEyeCommandKey,
  isChineseMode,
  hideAlert,
}: Props) => {
  const { width } = useWindowSize()
  const [inputTilesCounter, setInputTilesCounter] = useState(0)

  const hasFourMeldsAndMissingEyesPair = () => {
    let numberOfMelds = 0
    let missingEyesPair = true
    for (const item of tilesInputData) {
      if (item instanceof Meld) {
        numberOfMelds++
        if (item.getMeldType() === MeldType.EYES) {
          missingEyesPair = false
        }
      }
    }
    return numberOfMelds === 4 && missingEyesPair
  }

  const countTileOccurrence = (specifiedChar: string) => {
    let count = 0
    for (const char of tilesInput) {
      if (char === specifiedChar) {
        count++
      }
    }
    return count
  }

  const tileIsUsedUp = (specifiedChar: string) => {
    return countTileOccurrence(specifiedChar) >= occurrenceLimit
  }

  const isNotSuitableToBeEyesPair = (specifiedChar: string) => {
    const sizeOfEyesPair = 2
    return countTileOccurrence(specifiedChar) + sizeOfEyesPair > occurrenceLimit
  }

  const isNotSuitableToFormPong = (specifiedChar: string) => {
    countTileOccurrence(specifiedChar)
    const sizeOfPong = 3
    return countTileOccurrence(specifiedChar) + sizeOfPong > occurrenceLimit
  }

  const isNotSuitableToFormChow = (specifiedChar: string) => {
    const tile1 = new Tile(specifiedChar)
    const suit = tile1.getSuit()
    const value = tile1.getValue()
    if (suit === 'honor') {
      return false
    }
    const tile2 = new Tile({ suit, value: value + 1 })
    const tile3 = new Tile({ suit, value: value + 2 })
    return (
      countTileOccurrence(specifiedChar) + 1 > occurrenceLimit ||
      countTileOccurrence(tile2.toString()) + 1 > occurrenceLimit ||
      countTileOccurrence(tile3.toString()) + 1 > occurrenceLimit
    )
  }

  const isNotSuitableToFormKong = (specifiedChar: string) => {
    const sizeOfKong = 4
    return countTileOccurrence(specifiedChar) + sizeOfKong > occurrenceLimit
  }

  const isExceedingLimit = (specifiedChar: string) => {
    return (
      tileIsUsedUp(specifiedChar) ||
      (command === 'form eyes' && isNotSuitableToBeEyesPair(specifiedChar)) ||
      (command === 'pong' && isNotSuitableToFormPong(specifiedChar)) ||
      (command === 'kong' && isNotSuitableToFormKong(specifiedChar)) ||
      (command === 'chow' && isNotSuitableToFormChow(specifiedChar))
    )
  }

  const [command, setCommand] = useState<null | Commands>(null)

  const onClick = (value: KeyValue) => {
    hideAlert()
    if (
      value === 'pong' ||
      value === 'chow' ||
      value === 'kong' ||
      value === 'form eyes'
    ) {
      if (value === command) {
        setCommand(null)
      } else {
        setCommand(value)
      }
      return
    }

    if (value === '13orphans') {
      setInputTilesCounter(inputTilesCounter + 13)
      // const thirteenOrphansTiles = '🀇🀏🀙🀡🀐🀘🀀🀁🀂🀃🀄🀅🀆'
      // const graphemeSplitter = new GraphemeSplitter()

      const tile1 = new Tile({ suit: 'character', value: 1 })
      const tile2 = new Tile({ suit: 'character', value: 9 })
      const tile3 = new Tile({ suit: 'dot', value: 1 })
      const tile4 = new Tile({ suit: 'dot', value: 9 })
      const tile5 = new Tile({ suit: 'bamboo', value: 1 })
      const tile6 = new Tile({ suit: 'bamboo', value: 9 })
      const tile7 = new Tile({ suit: 'honor', value: 1 })
      const tile8 = new Tile({ suit: 'honor', value: 2 })
      const tile9 = new Tile({ suit: 'honor', value: 3 })
      const tile10 = new Tile({ suit: 'honor', value: 4 })
      const tile11 = new Tile({ suit: 'honor', value: 5 })
      const tile12 = new Tile({ suit: 'honor', value: 6 })
      const tile13 = new Tile({ suit: 'honor', value: 7 })
      const tiles = [
        tile1,
        tile2,
        tile3,
        tile4,
        tile5,
        tile6,
        tile7,
        tile8,
        tile9,
        tile10,
        tile11,
        tile12,
        tile13,
      ]
      onChars(tiles)
      return
    }

    setCommand(null)
    if (value === 'CLEAR_ALL') {
      onClearAll()
    } else if (value === 'DELETE') {
      onDelete()
      setInputTilesCounter(inputTilesCounter - 1)
    } else {
      switch (command) {
        case 'pong': {
          setInputTilesCounter(inputTilesCounter + 3)
          const pong = new Meld([
            new Tile(value),
            new Tile(value),
            new Tile(value),
          ])
          onChar(pong)
          break
        }
        case 'chow': {
          setInputTilesCounter(inputTilesCounter + 3)
          const tile1 = new Tile(value)
          const suit = tile1.getSuit()
          const tileValue = tile1.getValue()
          const tile2 = new Tile({ suit, value: tileValue + 1 })
          const tile3 = new Tile({ suit, value: tileValue + 2 })
          const chow = new Meld([tile1, tile2, tile3])
          onChar(chow)
          break
        }
        case 'kong': {
          setInputTilesCounter(inputTilesCounter + 4)
          const kong = new Meld([
            new Tile(value),
            new Tile(value),
            new Tile(value),
            new Tile(value),
          ])
          onChar(kong)
          break
        }
        case 'form eyes': {
          setInputTilesCounter(inputTilesCounter + 2)
          const eyes = new Meld([new Tile(value), new Tile(value)])
          onChar(eyes)
          break
        }
        default: {
          setInputTilesCounter(inputTilesCounter + 1)
          const tile = new Tile(value)
          onChar(tile)
        }
      }
    }
  }

  const mapKeyValueToCreateKey = (value: KeyValue, index: number) => {
    if (index >= 7) {
      return (
        <Key
          key={uuidv4()}
          value={value}
          onClick={onClick}
          disabled={
            command === 'chow' || isExceedingLimit(value) || disableAllInputKeys
          }
        />
      )
    } else {
      return (
        <Key
          key={uuidv4()}
          value={value}
          onClick={onClick}
          disabled={isExceedingLimit(value) || disableAllInputKeys}
        />
      )
    }
  }

  const mapBonusKeyValueToCreateKey = (value: KeyValue) => (
    <Key
      key={uuidv4()}
      value={value}
      onClick={onClick}
      disabled={
        command === 'chow' || isExceedingLimit(value) || disableAllInputKeys
      }
    />
  )

  return (
    <div id="keyboard">
      <div className="flex justify-center mb-1 sm:text-3xl text-2xl">
        <Key
          width={50}
          height={50}
          value="pong"
          onClick={onClick}
          status={command === 'pong' ? 'selected' : undefined}
          disabled={disableAllInputKeys || hasFourMeldsAndMissingEyesPair()}
          id={'pong'}
        >
          {isChineseMode ? PONG['chinese'] : PONG['english']}
        </Key>
        <Key
          width={50}
          height={50}
          value="chow"
          onClick={onClick}
          status={command === 'chow' ? 'selected' : undefined}
          disabled={disableAllInputKeys || hasFourMeldsAndMissingEyesPair()}
          id={'chow'}
        >
          {isChineseMode ? CHOW['chinese'] : CHOW['english']}
        </Key>
        <Key
          width={50}
          height={50}
          value="kong"
          onClick={onClick}
          status={command === 'kong' ? 'selected' : undefined}
          disabled={disableAllInputKeys || hasFourMeldsAndMissingEyesPair()}
          id={'kong'}
        >
          {isChineseMode ? KONG['chinese'] : KONG['english']}
        </Key>
        <Key
          width={50}
          height={50}
          value="form eyes"
          onClick={onClick}
          status={command === 'form eyes' ? 'selected' : undefined}
          disabled={disableAllInputKeys || disableEyeCommandKey}
          id={'formEyes'}
        >
          {isChineseMode ? FORM_EYES['chinese'] : FORM_EYES['english']}
        </Key>
        <Key
          width={65}
          height={50}
          value="13orphans"
          onClick={onClick}
          disabled={disableAllInputKeys || tilesCounter(tilesInput) >= 2}
        >
          {isChineseMode
            ? THIRTEEN_ORPHANS['chinese']
            : THIRTEEN_ORPHANS['english']}
        </Key>
      </div>
      <div className="flex justify-center mb-1 sm:text-3xl text-2xl">
        {characters.map(mapKeyValueToCreateKey)}
      </div>
      <div className="flex justify-center mb-1 sm:text-3xl text-2xl">
        {dots.map(mapKeyValueToCreateKey)}
      </div>
      <div className="flex justify-center mb-1 sm:text-3xl text-2xl">
        {bamboos.map(mapKeyValueToCreateKey)}
      </div>

      <div className="flex justify-center mb-1 sm:text-3xl text-2xl">
        {bonus.map(mapBonusKeyValueToCreateKey)}
        <div>
          <div className="text-sm pl-1">
            <Key
              width={width > 640 ? 55 : 35}
              value="DELETE"
              onClick={onClick}
              disabled={tilesInput.length === 0}
            >
              <BackspaceIcon className="h-6 w-6" />
            </Key>
          </div>
        </div>
        <div>
          <div className="text-sm pl-1">
            <Key
              width={width > 640 ? 55 : 35}
              value="CLEAR_ALL"
              onClick={onClick}
              disabled={tilesInput.length === 0}
              id="clearAll"
            >
              <RefreshIcon className="h-6 w-6" />
            </Key>
          </div>
        </div>
      </div>
    </div>
  )
}
