export const UPDATE_IS_SELF_PICK = 'UPDATE_IS_SELF_PICK'
export const UPDATE_IS_FULLY_CONCEALED = 'UPDATE_IS_FULLY_CONCEALED'
export const UPDATE_ROUND_WIND = 'UPDATE_ROUND_WIND'
export const UPDATE_SEAT_WIND = 'UPDATE_SEAT_WIND'
export const UPDATE_EXTRA_TILES = 'UPDATE_EXTRA_TILES'
export const UPDATE_FLOWER_HANDS_BOOLEAN = 'UPDATE_FLOWER_HANDS_BOOLEAN'
export const UPDATE_EIGHT_IMMORTALS_CROSSING_THE_SEA_BOOLEAN =
  'UPDATE_EIGHT_IMMORTALS_CROSSING_THE_SEA_BOOLEAN'
export const UPDATE_IS_ROBBING_KONG = 'UPDATE_IS_ROBBING_KONG'
export const UPDATE_IS_WIN_BY_KONG = 'UPDATE_IS_WIN_BY_KONG'
export const UPDATE_IS_WIN_BY_DOUBLE_KONG = 'UPDATE_IS_WIN_BY_DOUBLE_KONG'
export const UPDATE_IS_WIN_BY_LAST_CATCH = 'UPDATE_IS_WIN_BY_LAST_CATCH'
export const UPDATE_SETTING_OF_BONUS_FAAN_DUE_TO_ZERO_EXTRA_TILES =
  'UPDATE_SETTING_OF_BONUS_FAAN_DUE_TO_ZERO_EXTRA_TILES'
export const RESET_BONUS_CONFIG = 'RESET_BONUS_CONFIG'
