import React, { ReactNode } from 'react'
import classnames from 'classnames'
import { Commands, KeyValue } from '../../lib/keyboard'
import { CharStatus } from '../../lib/statuses'
import { RenderTile } from '../render/Render'

type Props = {
  children?: ReactNode
  value: KeyValue
  width?: number
  height?: number
  status?: CharStatus
  disabled?: boolean
  onClick: (value: KeyValue | Commands) => void
  id?: string
}

export const Key = ({
  children,
  status,
  width = 40,
  height = 58,
  value,
  disabled,
  onClick,
  id,
}: Props) => {
  const classes = classnames(
    'flex items-center justify-center rounded mx-0.5 cursor-pointer text-sm select-none',
    {
      'bg-slate-200 hover:bg-slate-300 active:bg-slate-400': !status,
      'bg-slate-100 hover:bg-slate-100 active:bg-slate-100': disabled,
      'bg-red-300 hover:bg-red-300 active:bg-red-300 dark:bg-amber-300':
        status === 'selected',
    }
  )

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value)
    event.currentTarget.blur()
  }

  const textColor = disabled ? '#BBB' : 'black'
  return (
    <button
      style={{ width: `${width}px`, height: `${height}px`, color: textColor }}
      className={classes}
      onClick={handleClick}
      disabled={disabled}
      id={id}
    >
      {children || <RenderTile tile={value} disabled={disabled} />}
    </button>
  )
}
