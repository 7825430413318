import React from 'react'
import { KeyValue } from '../../lib/keyboard'

type Props = {
  tile: KeyValue | string | undefined
  disabled?: boolean
}

export const RenderTile = (props: Props) => {
  if (props.tile === undefined) return null
  const styleOfDisabled = {
    opacity: 0.2,
    filter: 'grayscale(100%)',
  }
  return (
    <img
      style={props.disabled === true ? styleOfDisabled : undefined}
      className="p-1 block drop-shadow-tile-light"
      src={`/tiles/${tileToSVGMap[props.tile]}`}
      alt={tileToNameMap[props.tile]}
    />
  )
}

const tileToSVGMap: { [id: string]: string } = {
  '🀇': 'character1.svg',
  '🀈': 'character2.svg',
  '🀉': 'character3.svg',
  '🀊': 'character4.svg',
  '🀋': 'character5.svg',
  '🀌': 'character6.svg',
  '🀍': 'character7.svg',
  '🀎': 'character8.svg',
  '🀏': 'character9.svg',
  '🀙': 'dot1.svg',
  '🀚': 'dot2.svg',
  '🀛': 'dot3.svg',
  '🀜': 'dot4.svg',
  '🀝': 'dot5.svg',
  '🀞': 'dot6.svg',
  '🀟': 'dot7.svg',
  '🀠': 'dot8.svg',
  '🀡': 'dot9.svg',
  '🀐': 'bamboo1.svg',
  '🀑': 'bamboo2.svg',
  '🀒': 'bamboo3.svg',
  '🀓': 'bamboo4.svg',
  '🀔': 'bamboo5.svg',
  '🀕': 'bamboo6.svg',
  '🀖': 'bamboo7.svg',
  '🀗': 'bamboo8.svg',
  '🀘': 'bamboo9.svg',
  '🀀': 'east.svg',
  '🀁': 'south.svg',
  '🀂': 'west.svg',
  '🀃': 'north.svg',
  '🀆': 'whiteDragon.png',
  '🀅': 'greenDragon.svg',
  '🀄': 'redDragon.svg',
}

const tileToNameMap: { [id: string]: string } = {
  '🀇': 'Character 1',
  '🀈': 'Character 2',
  '🀉': 'Character 3',
  '🀊': 'Character 4',
  '🀋': 'Character 5',
  '🀌': 'Character 6',
  '🀍': 'Character 7',
  '🀎': 'Character 8',
  '🀏': 'Character 9',
  '🀙': 'Dot 1',
  '🀚': 'Dot 2',
  '🀛': 'Dot 3',
  '🀜': 'Dot 4',
  '🀝': 'Dot 5',
  '🀞': 'Dot 6',
  '🀟': 'Dot 7',
  '🀠': 'Dot 8',
  '🀡': 'Dot 9',
  '🀐': 'Bamboo 1',
  '🀑': 'Bamboo 2',
  '🀒': 'Bamboo 3',
  '🀓': 'Bamboo 4',
  '🀔': 'Bamboo 5',
  '🀕': 'Bamboo 6',
  '🀖': 'Bamboo 7',
  '🀗': 'Bamboo 8',
  '🀘': 'Bamboo 9',
  '🀀': 'East',
  '🀁': 'South',
  '🀂': 'West',
  '🀃': 'North',
  '🀆': 'White Dragon',
  '🀅': 'Green Dragon',
  '🀄': 'Red Dragon',
}
