import React from 'react'
import { XIcon } from '@heroicons/react/outline'

type Props = {
  text: string
  delete: () => void
}

export const Tag = (props: Props) => {
  if (props.text.trim().length === 0) {
    return <></>
  }
  return (
    <div className="py-1 px-4 bg-white mx-2 my-1 border-2 border-slate-300 rounded-3xl flex justify-between text-sm">
      <div className="inline-block">#{props.text}</div>
      <div className="flex justify-center items-center ">
        <XIcon
          className=" ml-2 h-3 w-3 hover:cursor-pointer hover:text-white hover:bg-red-600 rounded"
          onClick={props.delete}
        />
      </div>
    </div>
  )
}
