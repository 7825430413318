import React from 'react'
import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { v4 as uuidv4 } from 'uuid'
import { EAST, SOUTH, WEST, NORTH } from '../../constants/strings'

const winds = [EAST, SOUTH, WEST, NORTH]

export default function RadioButton(props: {
  isChineseMode: boolean
  onChange: (input: string | null) => void
  initValue: null | string
}) {
  const [selected, setSelected] = useState<string | null>(props.initValue)

  return (
    <div className="w-full px-4 py-2">
      <div className="w-full max-w-md mx-auto">
        <RadioGroup
          value={selected}
          onChange={setSelected}
          className="inline-block"
        >
          <div className="inline-block grid sm:grid-cols-4 grid-cols-2">
            {winds.map((wind) => (
              <div
                key={uuidv4()}
                onClick={() => {
                  props.onChange(wind['english'])
                }}
              >
                <RadioGroup.Option
                  value={wind['english']}
                  className={({ active, checked }) =>
                    `${
                      active
                        ? 'ring-2 ring-offset-2 ring-offset-sky-700 dark:ring-offset-cyan-400 ring-white ring-opacity-60'
                        : ''
                    }
                  ${
                    checked
                      ? 'bg-sky-900 dark:bg-sky-500 bg-opacity-75 text-white'
                      : 'bg-white'
                  }
                relative rounded-lg shadow-md px-5 py-4 my-2 cursor-pointer inline-flex mx-2 focus:outline-none justify-center`
                  }
                >
                  <div className="text-base">
                    {props.isChineseMode ? wind['chinese'] : wind['english']}
                  </div>
                </RadioGroup.Option>
              </div>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  )
}
